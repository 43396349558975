import moment from 'moment';
import 'owl.carousel';

jQuery(document).ready(function ($) {

  // smooth scrolling 
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();

    var aid = $(this).attr("href");
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
  })


  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("header").toggleClass('active')
    $("nav .menu-navigation-menu-container").toggleClass('active')
    $("nav .social").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('header').hasClass('active')) {
      $("header").toggleClass('active')
      $("nav .menu-navigation-menu-container").toggleClass('active')
      $("nav .social").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })

  // VIDEO CAROUSEL
  function createVideoCarousel() {
    console.log("Initializing video carousel...");
    const videoCarousel = $("#video-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    videoCarousel.owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 1000,
      margin: 55,
      center: true,
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createVideoCarousel()

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_2087527/events?app_id=45PRESS_delacey',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0; 
      let maxVisibleDates = 5;
 
      if (data.length) {
        for (let event of data) {
          n++;
          let hideClass = n > maxVisibleDates ? 'hidden-event' : '';
          html += '<div class="event-group ' + hideClass + '">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }

        events.html(html);
        $("#toggle-dates").hide()

        if (n > maxVisibleDates) {
          $("#toggle-dates").show();
          $(".hidden-event").hide();
          $("#toggle-dates").text("Show More");
        } else {
          $("#toggle-dates").hide();
        }

      } else {
        events.html('<span class="no-events">Check back soon for more updates!</span>');
      }
    }
  });

  // Toggle Dates Functionality
  $("#toggle-dates").click(function (event) {
    event.preventDefault();

    const hiddenEvents = $(".hidden-event");
    if (hiddenEvents.is(":visible")) {
      hiddenEvents.slideUp();
      $(this).text("Show More Dates");
    } else {
      hiddenEvents.slideDown();
      $(this).text("Show Less Dates");
    }
  });
});